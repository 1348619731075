import React from 'react';
import './DeleteAccount.css';

const DeleteAccount = () => {
    return (
        <div className="deleteAccount-container">
            <h1>Delete Account</h1>
            <p>We're sorry to see you go. Please follow the instructions below to delete your account.</p>
            <ol>
                <li>Ensure you are logged in to your account.</li>
                <li>Navigate to your account settings page.</li>
                <li>Scroll down to the "Delete Account" section.</li>
                <li>Click the "Delete Account" button.</li>
                <li>You will be prompted to confirm your decision. Please read the information carefully.</li>
                <li>If you are sure, click "Confirm" to permanently delete your account.</li>
            </ol>
            <p className="warning">Warning: This action is irreversible. Once your account is deleted, all your data will be permanently removed and cannot be recovered.</p>
          
        </div>
    );
}

export default DeleteAccount;
