import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Footer from '../../compos/Footer/Footer';
import Header from '../../compos/Header/Header';
import UserAdminPanel from '../UserAdminPanel/UserAdminPanel';
import AuthorityAdminPanel from '../AuthorityAdminPanel/AuthorityAdminPanel';
import UploadPost from '../UploadPost/UploadPost';

import './AdminPanel.css';
import { LoginProps } from '../Login/Login';
import { User } from '../../compos/SinglePostHelper/SinglePostHelper';
import { useUser } from '../../UserContext';

const AdminPanel = () => {
  const {user,set_user} = useUser();
  console.log(user);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [userData, setUserData] = useState(null);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [activeComponent, setActiveComponent] = useState<string>(''); // State for tracking active component
  
  console.log("This is user",user);
  const renderActiveComponent = () => {
    switch (activeComponent) {
      case '':
        return (<div className='admin-panel-container'><h1>Welcome to Admin Panel</h1></div>);
      case 'UserAdminPanel':
        return <UserAdminPanel />;
      case 'AuthorityAdminPanel':
        return <AuthorityAdminPanel />;
      case 'UploadPost':
        return <UploadPost />
      // Add cases for other components if needed
      default:
        return null;
    }
  };

  const handleGetUser = async () => {
    try {
      console.log("This is phoneNumber:",phoneNumber);
      const response = await fetch(`https://backend.b-reporter.com/users/${phoneNumber}`);
      
      const data = await response.json();
      console.log(data);
      setUserData(data);
      setResponseMessage(JSON.stringify(data));
      
    } catch (error) {
      setResponseMessage('Error fetching user data');
    }
  };

  const handleBlockUser = async () => {
    try {
      const response = await fetch(`https://backend.b-reporter.com/users/${phoneNumber}/block`, { method: 'PUT' });
      const data = await response.json();
      setUserData(data);
      setResponseMessage(JSON.stringify(data));
    } catch (error) {
      setResponseMessage('Error blocking user');
    }
  };

  const handleUnblockUser = async () => {
    try {
      const response = await fetch(`https://backend.b-reporter.com/users/${phoneNumber}/unblock`, { method: 'PUT' });
      const data = await response.json();
      setUserData(data);
      setResponseMessage(JSON.stringify(data));
    } catch (error) {
      setResponseMessage('Error blocking user');
    }
  };
  const handleDeleteUser = async () => {
    try {
      const response = await fetch(`https://backend.b-reporter.com/users/${phoneNumber}`, { method: 'DELETE' });
      const data = await response.json();
      setUserData(data);
      setResponseMessage(JSON.stringify(data));
    } catch (error) {
      setResponseMessage('Error deleting user');
    }
  };

  const handleGetAnonymousUser = async () => {
    try {
      const response = await fetch(`https://backend.b-reporter.com/users/${phoneNumber}`);
      const data = await response.json();
      setResponseMessage(phoneNumber === 'anonymous' ? JSON.stringify(data) : 'Unauthorized access to non-anonymous user data.');
      setUserData(data);
    } catch (error) {
      setResponseMessage('Error fetching anonymous user data');
    }
  };

  // if (user == null) {
  //   return <Navigate to="/login" />;
  // }

  return (<>
  <Header  />
    <div className='admin-panel-bg d-flex flex-column justify-content-between'>
      
  
  {/* Navbar */}

      {/* <div className="vertical-nav">
        <ul className="nav nav-underline flex-column">
          <li className="nav-item">
            <a className={`nav-link vertical-nav-bar ${activeComponent === '' ? 'active' : ''}`} aria-current="page"  onClick={() => setActiveComponent('')}>Home</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link  vertical-nav-bar ${activeComponent === 'UserAdminPanel' ? 'active' : ''}`} href="#" onClick={() => setActiveComponent('UserAdminPanel')}>User</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link vertical-nav-bar ${activeComponent === 'AuthorityAdminPanel' ? 'active' : ''}`} href="#" onClick={() => setActiveComponent('AuthorityAdminPanel')}>Authority</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link  vertical-nav-bar ${activeComponent === 'UploadPost' ? 'active' : ''}`} href="#" onClick={() => setActiveComponent('UploadPost')}>Upload Post</a>
          </li>
          
        </ul>
      </div> */}
    <div className='admin-container'>
  <nav className="left-navbar navigation-bar">
                        <div>
                            <div>
                                <a >
                                    <div onClick={() => setActiveComponent('')}>
                                        {/* <i className="fa fa-light fa-house-user fa-2xl"></i> */}
                                        <span className={`${activeComponent === '' ? 'active' : ''}`}>Home</span>
                                    </div>
                                </a>
                            </div>

                            <div>
                                <a >
                                    <div onClick={() => setActiveComponent('UserAdminPanel')}>
                                        {/* <i className="fa fa-thin fa-atom fa-2xl"></i> */}
                                        <span className={`${activeComponent === 'UserAdminPanel' ? 'active' : ''}`}>User</span>
                                    </div>
                                </a>
                            </div>

                            <div>
                                <a >
                                    <div onClick={() => setActiveComponent('AuthorityAdminPanel')}>
                                        {/* <i className="fa fa-thin fa-droplet fa-2xl"></i> */}
                                        <span className={`${activeComponent === 'AuthorityAdminPanel' ? 'active' : ''}`}>Authority</span>
                                    </div>
                                </a>
                            </div>

                            <div>
                                <a >
                                    <div onClick={() => setActiveComponent('UploadPost')}>
                                        {/* <i className="fa fa-thin fa-sack-dollar fa-2xl"></i> */}
                                        <span className={`${activeComponent === 'UploadPost' ? 'active' : ''}`}>Upload Post</span>
                                    </div>
                                </a>
                            </div>

                           

                           
                        </div>
                    </nav>
      
      <div className="content-area">
        {renderActiveComponent()}
      </div>
      </div>
      <Footer />
    </div>
    
    </>)
   
  
};

export default AdminPanel;


