import React, { useState } from 'react';
import './AuthorityAdminPanel.css';

const AuthorityAdminPanel = () => {
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [showAlert, setShowAlert] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    verificationDocuments: null as File | null,
  });

  const handleCreateAuthority = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', formData.name);
    data.append('phoneNumber', formData.phoneNumber);
    if (formData.verificationDocuments) {
      data.append('file', formData.verificationDocuments);
    }

    try {
      const response = await fetch('https://backend.b-reporter.com/users/authority/createAuthority', {
        method: 'POST',
        body: data,
        credentials: 'include' as RequestCredentials
      });

      const result = await response.json();
      if (response.ok) {
        console.log("Authority created successfully");
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        setResponseMessage(result.message);
      } else {
        setResponseMessage(result.message || 'Error creating authority');
      }
    } catch (error) {
      setResponseMessage('Error creating authority');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevData) => ({
      ...prevData,
      verificationDocuments: e.target.files ? e.target.files[0] : null,
    }));
  };

  return (
    <div className="admin-panel-bg">
    <div className="admin-panel-container">
      <button className="admin-panel-button" onClick={() => setShowForm(true)}>
        Create Authority
      </button>

      {showForm && (
        <form onSubmit={handleCreateAuthority}>
            <div className="form-group">
            <label htmlFor="formGroupExampleInput">Name:</label>
            <input
              type="text"
              className="form-control"
              id="formGroupExampleInput"
              name="name"
              placeholder="Enter name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="formGroupExampleInput2">Phone Number:</label>
            <input
              type="text"
              className="form-control"
              id="formGroupExampleInput2"
              name="phoneNumber"
              placeholder="Enter phone number"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleFormControlFile1">Verification Documents:</label>
            <input
              type="file"
              className="form-control-file"
              id="exampleFormControlFile1"
              name="verificationDocuments"
              onChange={handleFileChange}
              required
            />
          </div>
      

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      )}
      {showAlert && (
        <div className="alert alert-success d-flex align-items-center" role="alert">
          <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Success:">
            <use href="#check-circle-fill" />
          </svg>
          <div>
            {responseMessage || 'Authority created successfully.'}
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default AuthorityAdminPanel;
