// src/UserContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';
import { User } from './compos/SinglePostHelper/SinglePostHelper';

interface UserContextProps {
  user: User | null;
  set_user: React.Dispatch<React.SetStateAction<User | null>>;
  isAdmin: boolean;
  setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  getPostId: string;
  setGetPostId: React.Dispatch<React.SetStateAction<string>>;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, set_user] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [getPostId, setGetPostId] = useState<string>("");

  return (
    <UserContext.Provider value={{ user, set_user, isAdmin, setIsAdmin, isLoggedIn, setIsLoggedIn, getPostId, setGetPostId }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
