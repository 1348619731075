import React from 'react';
import './RiskoMeter.css';

interface RiskoMeterProps {
  value: number;
  personCount: number;
  facesCount: number;
  fireCount: number;
  weaponsCount: number;
}

const RiskoMeter: React.FC<RiskoMeterProps> = ({ value, personCount, facesCount, fireCount, weaponsCount }) => {
  const getStrokeDasharray = () => {
    const radius = 90; // Radius of the arc
    const circumference = Math.PI * radius;
    const offset = ((100 - value) / 100) * circumference;
    return `${circumference - offset} ${offset}`;
  };

  return (
    <div className="riskometer">
      <div className="gauge">
        <svg width="200" height="100" viewBox="0 0 200 100">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="purple" />
              <stop offset="100%" stopColor="blue" />
            </linearGradient>
          </defs>
          <path
            d="M 10,100 A 90,90 0 0,1 190,100"
            stroke="lightgray"
            strokeWidth="15"
            fill="none"
          />
          <path
            d="M 10,100 A 90,90 0 0,1 190,100"
            stroke="url(#gradient)"
            strokeWidth="15"
            fill="none"
            strokeDasharray={getStrokeDasharray()}
            strokeDashoffset="0"
            strokeLinecap="round"
          />
        </svg>
        <div className="gauge-text">{value.toFixed(1)}</div>
      </div>
      <div className="stats">
        <div className="stat">
          <div>Person Count</div>
          <div>{personCount}</div>
        </div>
        <div className="stat">
          <div>Faces Count</div>
          <div>{facesCount}</div>
        </div>
        <div className="stat">
          <div>Fire/Fire Objects</div>
          <div>{fireCount}</div>
        </div>
        <div className="stat">
          <div>Weapons Count</div>
          <div>{weaponsCount}</div>
        </div>
      </div>
    </div>
  );
};

export default RiskoMeter;
