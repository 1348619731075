import React, {useState, useEffect} from 'react';
// import './UploadPost.css';





const UploadPost = () => {

    const [postData, setPostData] = useState({
        mediaIds: [] as string[], // Initialize as an empty array
        title: '',
        body: '',
        tags: 'General News',
        isAnonymous: false,
     });

     const [mediaData, setMediaData] = useState({
        file: null as File | null,
        latitude: '',
        longitude: '',
        device: '',
        mac: '',
        ip: '',
     });

     const [mediaId, setMediaId] = useState<string | null>(null);
     const [showAlert, setShowAlert] = useState(false);

     useEffect(() => {
            // Get Geolocation
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  const { latitude, longitude } = position.coords;
                //   console.log("latitude:",latitude.toString());
                //   console.log("longitude:",longitude.toString());
                  setMediaData((prevData) => ({
                    ...prevData,
                    latitude: latitude.toString(),
                    longitude: longitude.toString(),
                  }));
                },
                (error) => console.error(error),
                { enableHighAccuracy: true }
              );
            }
        
            // Get Device Info
            const userAgent = navigator.userAgent;
            // console.log("This is device:",userAgent);
            setMediaData((prevData) => ({
              ...prevData,
              device: userAgent,
            }));
        
            // Get IP Address
            fetch('https://api.ipify.org?format=json')
              .then((response) => response.json())
              .then((data) => {
                setMediaData((prevData) => ({
                  ...prevData,
                  ip: data.ip,
                }));
                // console.log("This is ip address",data.ip);
              })
              .catch((error) => console.error('Error fetching IP address:', error));
        
            // Note: MAC address cannot be obtained directly from a browser for security reasons
            setMediaData((prevData) => ({
              ...prevData,
              mac: 'MAC address is not accessible via browser',
            }));
          }, []);
    
     
          const updatePostData = async (mediaId: string) => {
            return new Promise<void>((resolve) => {
                setPostData((prevData) => {
                    const updatedData = { ...prevData, mediaIds: [mediaId] };
                    resolve();
                    return updatedData;
                });
            });
        };
      


      const handleCreatePost = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      
        const mediaFormData = new FormData();
        mediaFormData.append('latitude', mediaData.latitude);
        mediaFormData.append('longitude', mediaData.longitude);
        mediaFormData.append('device', mediaData.device);
        mediaFormData.append('mac', mediaData.mac);
        mediaFormData.append('ip', mediaData.ip);

        if (mediaData.file) {
            mediaFormData.append('file', mediaData.file);
        }
        console.log("THis is media form data", mediaFormData);
        try {
            // Upload media data
            const mediaResponse = await fetch('https://backend.b-reporter.com/api/createMedia', {
                method: 'POST',
                body: mediaFormData,
                credentials: 'include' as RequestCredentials
            });


            const mediaResult = await mediaResponse.json();
            console.log("This is mediaResult:",mediaResult);

            // Assuming mediaResult contains the media ID
            const mediaId = mediaResult;
             // Assuming mediaResult contains the media ID
      if (mediaResult) {
        setMediaId(mediaResult);
        console.log("media created successfully, this is media ID", mediaResult);
      }

            // await updatePostData(mediaId);

            // const postDataToSend =  {
            //     mediaIds: postData.mediaIds.map(id => id.toString()),
            //     title: postData.title,
            //     body: postData.body,
            //     tags: postData.tags,
            //     isAnonymous: postData.isAnonymous,
            // };   
            const postDataToSend = {
                mediaIds: [mediaResult.toString()],
                title: postData.title,
                body: postData.body,
                tags: postData.tags,
                isAnonymous: postData.isAnonymous,
              };
         console.log("This is mediaIds of postData to send:",postDataToSend.mediaIds);
            // Submit post data
            const postResponse = await fetch('https://backend.b-reporter.com/api/createPost', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postDataToSend),
                credentials: 'include' as RequestCredentials
            });
    

            const postResult = await postResponse.json();

            console.log('Post created successfully:', postResult);
            setShowAlert(true);

        // Hide alert after 3 seconds
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);

        } catch (error) {
            console.error('Error creating post:', error);
        }
    };

     const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setPostData((prevData) => ({
          ...prevData,
          [name]: value,      
        }));
      };

      const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMediaData((prevData) => ({
          ...prevData,
          file: e.target.files ? e.target.files[0] : null,
        }));
      };

    return (
    <div className='h-screen p-10'>

        <div className="flex flex-col bg-white p-20 text-left shadow-lg ">
        <form onSubmit={handleCreatePost}>
          <div className='mb-3'>
          <label htmlFor="formGroupExampleInput">Title:</label>
          <input
            type="text"
            className="w-full p-1 border border-black rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-400 resize-none"
            id="formGroupExampleInput"
            name="title"
            placeholder="Enter Title"
            value={postData.title}
            onChange={handleInputChange}
          />
          </div>
          <div className='mb-3'>
          <label htmlFor="formGroupExampleInput2">Description:</label>
            <textarea
                id="user-input"
                rows={5}
                itemType='text'
                className="w-full p-1 border border-black rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-400 resize-none"
                placeholder="Description"
                value={postData.body}
                onChange={handleInputChange}
            />
          </div>
        <div className='mb-3'>
          <label htmlFor="formGroupExampleInput3">Tag:</label>
          <select
            
            className="w-full bg-white border border-black p-2 rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-400 resize-none border-solid"
            id="formGroupExampleInput3"
            name="tags"
            
            value={postData.tags}
            onChange={handleInputChange}
          >
            <option value="General News">General News</option>
            <option value="Research">Research</option>
            <option value="Crime">Crime</option>
            <option value="Fraud">Fraud</option>
            <option value="Politics">Politics</option>
            <option value="Initiatives">Initiatives</option>
            <option value="Opinions">Opinions</option>
          </select>
        </div>
        <div className='mb-3'>
          <label htmlFor="exampleFormControlFile1">Upload image:</label>
          <input
            type="file"
            className="w-full bg-white border border-black p-2 rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-400 resize-none border-solid"
            id="exampleFormControlFile1"
            name="file"
            onChange={handleFileChange}
          />
        </div>
        <button type="submit" className="px-6 py-2 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700 transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 ">
          Upload
        </button>
      </form>

      {showAlert && (
        <div className="" role="alert">
          <svg className="" role="img" aria-label="Success:">
            <use href="#check-circle-fill" />
          </svg>
          <div>
            Post uploaded successfully.
          </div>
        </div>
      )}
    </div>
    </div>
);
}

export default UploadPost;