import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../compos/Header/Header';
import AllPosts from '../../compos/AllPosts/AllPosts';
import '../News/News.css';
import SinglePostHelper, { User } from '../../compos/SinglePostHelper/SinglePostHelper';
import SingleReportHelper from '../../compos/SingleReportHelper/SingleReportHelper';
import { LoginProps } from '../Login/Login';
import { useUser } from '../../UserContext';

function SingleReport() {
	return (
		<div className='News'>
			<SingleReportHelper  />
		</div>
	);
}

export default SingleReport;
