import React from 'react';
// @ts-ignore
// import { Slide } from 'hero-slider';
// @ts-ignore
// import HeroSlider from 'hero-slider';
// import Pic1 from '../../Media/Icons/pic1.png';
// import Pic2 from '../../Media/Icons/pic2.png';
// import Pic3 from '../../Media/Icons/pic3.png';
// import Pic4 from '../../Media/Icons/pic4.png';

import bgimage from '../../Media/Images/home_three.jpg';
import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './BasicSlider.css';

const BasicSlider = () => {
	const navigate = useNavigate();
	const [isExpanded, setIsExpanded] = useState(false);

	const handleToggle = () => {
	  setIsExpanded(prevState => !prevState);
	};
	return (

		// Slider removed, Hero section from template starts

		<section id='hero' className='hero d-flex align-items-center basic-slider section-bg'>
			<div className='container'>
				<div className='row justify-content-between gy-5'>
					<div className='col-lg-5 order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start'>
						<h3 data-aos='fade-up'>
							Be Yourself a Reporter,
							<br />	
							With My News, My Report 	
							<br />
							from B-Reporter.
						</h3>
						<div className='height: 12px;'></div>
						
						{!isExpanded && (
                    <p className='fst-italic'>
                      Uncertain about the Place, Time and Source (PTS) of a news floating over social media? Confused with agenda based paid, fake news or fact checks? B-Reporter brings <b>location-aware</b> and <b>scene-aware</b> social media platform – My News, My Report...
                      <button onClick={handleToggle} className="btn btn-link">Read more</button>
                    </p>
                  )}
                  {isExpanded && (
                    <p className='fst-italic'>
                      Uncertain about the Place, Time and Source (PTS) of a news floating over social media? Confused with agenda based paid, fake news or fact checks? B-Reporter brings <b>location-aware</b> and <b>scene-aware</b> social media platform – My News, My Report. It works on the principle of live record and report, thus avoiding post-facto and helping the reporter timely if required/possible. Not allowing Photoshop and uploading, maintains the sanctity of the report. Bringing ground report from people on ground will further add to the authenticity. <br />
                      <b>Multimodality</b> gives further flexibility in timely reporting before getting victimized. We are committed to connect victim to the authority for instant help, to eventually become victor. It empowers every individual to remain alert about happening in surroundings.
                      <button onClick={handleToggle} className="btn btn-link">Read less</button>
                    </p>
                  )}
						<div className='d-flex' data-aos='fade-up' data-aos-delay='200'>
							<button onClick={()=>{navigate('/login')}} className='btn-book-a-table'>
								Login
							</button>
							<a
								href='https://play.google.com/store/apps/details?id=com.iitd.breporter'
								className='glightbox btn-watch-video d-flex align-items-center'
							>
								<i className='fa-brands fa-google-play'></i>
								<span>Install App</span>
							</a>
							<div className='apk-download-container d-flex align-items-center btn-download-apk'>
								<i className="bi bi-android2"></i>
								<a href='https://backend.b-reporter.com/api/media/app.apk' className='apk-link'>
									<span>Download APK</span>
								</a>
							</div>
						</div>
					</div>
					<div className='col-lg-5 order-1 order-lg-2 text-center text-lg-start'>
						<img
							src={bgimage}
							className='img-fluid'
							width='600'
							style={{ mixBlendMode: 'multiply' }}
							alt=''
							data-aos='zoom-out'
							data-aos-delay='300'
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BasicSlider;
