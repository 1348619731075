import React, { useEffect } from 'react';
import Header from '../../compos/Header/Header';
import AllPosts from '../../compos/AllPosts/AllPosts';
import './Reports.css';
import { Navigate } from 'react-router-dom';
import { LoginProps } from '../Login/Login';
import MyReports from '../../compos/AllReports/MyReports';
import { User } from '../../compos/SinglePostHelper/SinglePostHelper';
import { useUser } from '../../UserContext';

function Reports() {
	const {user,set_user} = useUser();
	
	if (user == null) {
		return (
			<div>
				<Navigate to='/login' />
			</div>
		)
	}
	else{
		return (
			<div>
			
				<MyReports />
				
				<div>
					<Header />
					
				</div>
			</div>
		);
	}
}

export default Reports;
