import './MyReports.css';
import '../AllPosts/MyFeed.css';
import RiskoMeter from '../RiskoMeter/RiskoMeter';
import React, { useState, useEffect,useRef } from 'react';
import Geocode from 'react-geocode';
import GN_Icon from '../../Media/Icons/TopicsIcons/GN_Icon.svg';
import Events_Icon from '../../Media/Icons/TopicsIcons/Events_Icon.svg';
import Research_Icon from '../../Media/Icons/TopicsIcons/Research_Icon.svg';
import Crime_Icon from '../../Media/Icons/TopicsIcons/Crime_Icon.svg';
import Fraud_Icon from '../../Media/Icons/TopicsIcons/Fraud_Icon.svg';
import Politics_Icon from '../../Media/Icons/TopicsIcons/Politics_Icon.svg';
import Initiatives_Icon from '../../Media/Icons/TopicsIcons/Initiatives_Icon.svg';
import Opinions_Icon from '../../Media/Icons/TopicsIcons/Opinions_Icon.svg';
import GN_Icon_Selected from '../../Media/Icons/TopicsIcons/GN_Icon_Selected.svg';
import Events_Icon_Selected from '../../Media/Icons/TopicsIcons/Events_Icon_Selected.svg';
import Research_Icon_Selected from '../../Media/Icons/TopicsIcons/Research_Icon_Selected.svg';
import Crime_Icon_Selected from '../../Media/Icons/TopicsIcons/Crime_Icon_Selected.svg';
import Fraud_Icon_Selected from '../../Media/Icons/TopicsIcons/Fraud_Icon_Selected.svg';
import Politics_Icon_Selected from '../../Media/Icons/TopicsIcons/Politics_Icon_Selected.svg';
import Initiatives_Icon_Selected from '../../Media/Icons/TopicsIcons/Initiatives_Icon_Selected.svg';
import Opinions_Icon_Selected from '../../Media/Icons/TopicsIcons/Opinions_Icon_Selected.svg';
import Placeholder from '../../Media/Team/placeholder.png'
import { User } from '../SinglePostHelper/SinglePostHelper';
import { Link, Navigate,useNavigate } from 'react-router-dom';
import { Player, BigPlayButton, ControlBar, PlaybackRateMenuButton } from 'video-react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { makeNumber, timeDiff } from '../../utils/calc';
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, RedditShareButton, RedditIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { useUser } from '../../UserContext';
import ImageModal from '../ImageModal/ImageModal';

Geocode.setApiKey('AIzaSyBMyRKq-C8PhUUCZDXUg6y1MV4_k523T_8');
Geocode.setLanguage('en');
// Geocode.setRegion('in');
Geocode.setLocationType('ROOFTOP');

type TopicsType = {
    name: string;
    img: string;
    filter: boolean;
    key: number;
};

// Define MediaType and PostType
type MediaType = {
    format: string;
    internalURL: string;
    latitude: string;
    longitude: string;
  };
  


  type ReportType = {
    id: number;
    title: string;
    media: MediaType[];
    isAnonymous: boolean;
    updatedAt: string;
    createdAt:string
    commentCount: number;
    user: { name: string;
        id:number;
        profilepicURL:string;
     };
  };


export type AllReportDataType = {
    id: number;
    title: string;
    content?: string;
    format: string;
    image_url: any;
    media_filename: string;
    name: string;
    location: string;
    time: string;
    commentCount: number;
    
    
    profilepicURL:string;
    

};

interface RiskData {
    No_of_Faces: number;
    No_of_Fire_Objects: number;
    No_of_Persons: number;
    No_of_Weapons: number;
    Risk_Factor: number;
  }
const handleTopicToggle = (
    data: TopicsType,
    Topics: TopicsType[],
    set_Topics: React.Dispatch<React.SetStateAction<TopicsType[]>>
) => {
    const topics_selected_img_array = [
        GN_Icon_Selected,
        Events_Icon_Selected,
        Research_Icon_Selected,
        Crime_Icon_Selected,
        Fraud_Icon_Selected,
        Politics_Icon_Selected,
        Initiatives_Icon_Selected,
        Opinions_Icon_Selected,
    ];
    const topics_notselected_img_array = [
        GN_Icon,
        Events_Icon,
        Research_Icon,
        Crime_Icon,
        Fraud_Icon,
        Politics_Icon,
        Initiatives_Icon,
        Opinions_Icon,
    ];
    set_Topics(
        Topics.map((topic) => {
            if (topic === data) {
                if (topic.filter === false) {
                    return { ...topic, img: topics_selected_img_array[topic.key - 1], filter: !topic.filter };
                } else {
                    return { ...topic, img: topics_notselected_img_array[topic.key - 1], filter: !topic.filter };
                }
            } else {
                return topic;
            }
        })
    );
};

function MyReports() {
    const {user} = useUser();
    const navigate = useNavigate();
    const [AllReportData, set_AllReportData] = useState<AllReportDataType[]>([]);
    const [Topic, set_Topic] = useState<number>(0);
    const [Time, set_Time] = useState<number>(0);
    const [Distance, set_Distance] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [likes, setLikes] = useState<Record<number, boolean>>({}); // State for likes
    const [riskData, setRiskData] = useState<RiskData | null>(null);
    const [showRiskMeter, setShowRiskMeter] = useState(false);
    
    const storePreviousLocation = () => {
		localStorage.setItem('previousLocation', '/reports');
	  };
	
	  // Navigate back to the stored location (e.g., 'My Feed') when the component mounts
	  useEffect(() => {
		const previousLocation = localStorage.getItem('previousLocation');
		if (previousLocation) {
		  localStorage.removeItem('previousLocation'); // Clear stored location
		  navigate(previousLocation); // Navigate back to 'My Feed' or stored location
		}
	  }, [navigate]);

      const navigateToReport = (reportId: string) => {
        // Store the previous location before navigating to individual post
        storePreviousLocation();
        navigate(`/getreport/${reportId}`);
      };
   
   
    // Reference to the container
    const postContainerRef = useRef<HTMLDivElement>(null);

    const formatDateToRelativeTime = (dateString:string) => {
        const date = parseISO(dateString);
        return formatDistanceToNow(date, { addSuffix: true });
      };

      const handleRiskometerClick = async (mediaFilename: string) => {
        try {
          const response = await fetch(`http://20.244.37.235:8085/analyze/${mediaFilename}`, {
            method: 'GET',
            redirect: 'follow' as RequestRedirect,
            credentials: 'include' as RequestCredentials,
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setRiskData(data);
          setShowRiskMeter(true);
        } catch (error) {
          console.error('Error fetching risk data:', error);
        }
      };
    
      const handleRiskometerClose = () => {
        setShowRiskMeter(false);
      };


    

    const fetchPosts = async (page:number) => {
        const requestOptions: RequestInit = {
          method: 'GET',
          redirect: 'follow',
          credentials: 'include'
        };
       
        const baseUrl = 'https://backend.b-reporter.com/api/getReportsForAuthority';
        // const endpoint = Topic === 0 ? 'myFeed' : 'myFeedPro';
        const url = `${baseUrl}?limit=10&offset=${(page - 1) * 10}`;
    
        try {
          const response = await fetch(url, requestOptions);
          const result: ReportType[] = await response.json();
    
    
          const usefultemp: AllReportDataType[] = (await Promise.all(result.map(async (each) => {
            
            if (each.media[0].format.substring(0, 5) !== 'image') return null;
    
            let image = '';
            try {
              const mediaResponse = await fetch('https://backend.b-reporter.com/api/media/' + each.media[0].internalURL, requestOptions);
              const blob = await mediaResponse.blob();
              image = URL.createObjectURL(blob);
              
            } catch (error) {
              console.log('Error fetching media:', error);
              return null;
            }
    
            
            return {
              id: each.id,
              title: each.title,
              content: "",
              format: each.media[0].format.substring(0, 5),
              image_url: image,
              media_filename: each.media[0].internalURL,
              name: each.isAnonymous ? 'Anonymous' : each.user.name,
              location: `(${each.media[0].latitude}, ${each.media[0].longitude})`,
              time: `${each.createdAt}`,
              commentCount: each.commentCount,
             
              profilepicURL: each.user.profilepicURL

            } as AllReportDataType;
          }))).filter((post): post is AllReportDataType => post !== null);

           // Initialize likes state based on fetched posts
        // const initialLikes = usefultemp.reduce((acc, post) => {
        //     acc[post.id] = post.isLiked===1? true:false;// Assuming posts are initially not liked
        //     return acc;
        // }, {} as Record<number, boolean>);
        
    
          set_AllReportData((prevPosts) => [...prevPosts, ...usefultemp]);
        //   setLikes((prevLikes) => ({ ...prevLikes, ...initialLikes })); // Set the likes state
      
          // Scroll to top after loading more posts
          if (postContainerRef.current) {
            postContainerRef.current.scrollTop = 0;
        }
            } catch (error) {
            console.log('Error fetching posts:', error);
            }
        };

                
            
        useEffect(() => {
            set_AllReportData([]); // Clear existing posts
            setCurrentPage(1); // Reset current page to 1
            fetchPosts(1); // Fetch initial posts for the selected topic
        }, [Topic]);
    
        useEffect(() => {
            if (currentPage > 1) {
                fetchPosts(currentPage);
            }
        }, [currentPage]);
    
      const loadMorePosts = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    return (
        <div className='container-fluid container-myfeed'>
    <div className='row h-100'>
       
        <div className='col-10 col-lg-6 h-100 center'>
            <div className="content-header">
                <span>Hello {user?.name},</span>
            </div>
            {/* <div>
                <RiskoMeter  value={90}
        personCount={0}
        facesCount={0}
        fireCount={2}
        weaponsCount={2} />
            </div> */}
            <div className="posts-container" ref={postContainerRef}>
                {AllReportData.map(data => {
                    return (
                        <div className="post" key={data.id}>
                             {showRiskMeter && riskData && (
                                            <div className="riskometer-overlay">
                                            <button className="riskometer-close-button" onClick={handleRiskometerClose}>X</button>
                                            <RiskoMeter
                                                value={riskData.Risk_Factor}
                                                personCount={riskData.No_of_Persons}
                                                facesCount={riskData.No_of_Faces}
                                                fireCount={riskData.No_of_Fire_Objects}
                                                weaponsCount={riskData.No_of_Weapons}
                                            />
                                            </div>
                                        )}
                            <div className="left-column">
                                {data.profilepicURL === null || data.name === "Anonymous" ? (
                                    <img className="profile-image" src={Placeholder} alt="profile-pic" />
                                ) : (
                                    <img className="profile-image" src={`https://backend.b-reporter.com/profilepic/${data.profilepicURL}`} alt="profile-pic" />
                                )}
                            </div>
                            <div className="right-column">
                                <article className="top-row">
                                    <div className="post-header">
                                        <div className='name-time'>
                                            <strong>
                                                <span>{data.name}</span>
                                            </strong>
                                            <span className="time">{formatDateToRelativeTime(data.time)}</span>
                                        </div>
                                        <div className="details" onClick={() => handleRiskometerClick(data.media_filename)}>
                                            {/* <i className="fa fa-light fa-ellipsis fa-lg"></i> */}
                                            <i className="bi bi-speedometer2"></i>
                                        </div>
                                    </div>
                                    <p>{data.title}</p>
                                    <div className='post-img-container' style={{ position: 'relative' }} onClick={() =>  navigateToReport(data.id.toString()) }>
                                        <img src={data.image_url} alt="IIT-DELHI" className='post-image ' style={{ display: 'block', width: '100%' }} />
                                        <div className='water-mark' style={{ position: 'absolute', bottom: 0, left: 0 }}>
                                            <p style={{ margin: 0 }}>B-Reporter</p>
                                            <p style={{ margin: 0 }}>Created {formatDateToRelativeTime(data.time)}</p>
                                        </div>
                                    </div>
                                </article>
                                <div className="bottom-row">
                                    <div onClick={() =>  navigateToReport(data.id.toString()) }>
                                        <div>
                                            {/* <i className="fa fa-regular fa-comment fa-lg"></i> */}
                                            <i className="bi bi-chat-left "></i>
                                        </div>
                                        <span>{data.commentCount}</span>
                                    </div>
                                    
                                    
                                </div>
                                <div className='more-btn'>
                                    <span onClick={() =>  navigateToReport(data.id.toString()) }>Show More...</span>
                                    <p>{data.content}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <div className="load-more-container" onClick={loadMorePosts}>
                    <i className="fa fa-solid fa-chevron-down load-more-arrow"></i>
                </div>
            </div>
        </div>
    </div>
</div>

    );
}

export default MyReports;


