import './MyFeed.css';
import RiskoMeter from '../RiskoMeter/RiskoMeter';
import React, { useState, useEffect,useRef } from 'react';
import Geocode from 'react-geocode';
import GN_Icon from '../../Media/Icons/TopicsIcons/GN_Icon.svg';
import Events_Icon from '../../Media/Icons/TopicsIcons/Events_Icon.svg';
import Research_Icon from '../../Media/Icons/TopicsIcons/Research_Icon.svg';
import Crime_Icon from '../../Media/Icons/TopicsIcons/Crime_Icon.svg';
import Fraud_Icon from '../../Media/Icons/TopicsIcons/Fraud_Icon.svg';
import Politics_Icon from '../../Media/Icons/TopicsIcons/Politics_Icon.svg';
import Initiatives_Icon from '../../Media/Icons/TopicsIcons/Initiatives_Icon.svg';
import Opinions_Icon from '../../Media/Icons/TopicsIcons/Opinions_Icon.svg';
import GN_Icon_Selected from '../../Media/Icons/TopicsIcons/GN_Icon_Selected.svg';
import Events_Icon_Selected from '../../Media/Icons/TopicsIcons/Events_Icon_Selected.svg';
import Research_Icon_Selected from '../../Media/Icons/TopicsIcons/Research_Icon_Selected.svg';
import Crime_Icon_Selected from '../../Media/Icons/TopicsIcons/Crime_Icon_Selected.svg';
import Fraud_Icon_Selected from '../../Media/Icons/TopicsIcons/Fraud_Icon_Selected.svg';
import Politics_Icon_Selected from '../../Media/Icons/TopicsIcons/Politics_Icon_Selected.svg';
import Initiatives_Icon_Selected from '../../Media/Icons/TopicsIcons/Initiatives_Icon_Selected.svg';
import Opinions_Icon_Selected from '../../Media/Icons/TopicsIcons/Opinions_Icon_Selected.svg';
import Placeholder from '../../Media/Team/placeholder.png'
import { User } from '../SinglePostHelper/SinglePostHelper';
import { Link, Navigate,useNavigate } from 'react-router-dom';
import { Player, BigPlayButton, ControlBar, PlaybackRateMenuButton } from 'video-react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { makeNumber, timeDiff } from '../../utils/calc';
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, RedditShareButton, RedditIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { useUser } from '../../UserContext';
import ImageModal from '../ImageModal/ImageModal';

Geocode.setApiKey('AIzaSyBMyRKq-C8PhUUCZDXUg6y1MV4_k523T_8');
Geocode.setLanguage('en');
// Geocode.setRegion('in');
Geocode.setLocationType('ROOFTOP');

type TopicsType = {
    name: string;
    img: string;
    filter: boolean;
    key: number;
};

// Define MediaType and PostType
type MediaType = {
    format: string;
    internalURL: string;
    latitude: string;
    longitude: string;
  };
  
  type PostType = {
    id: number;
    title: string;
    media: MediaType[];
    isAnonymous: boolean;
    updatedAt: string;
    createdAt:string
    commentCount: number;
    likeCount: number;
    tags: string;
    isLiked:number;
    user: { name: string;
        id:number;
        profilepicURL:string;
     };
  };

export type AllPostDataType = {
    id: number;
    title: string;
    content?: string;
    format: string;
    image_url: any;
    media_filename: string;
    name: string;
    location: string;
    time: string;
    commentCount: number;
    likeCount: number;
    tags: string;
    profilepicURL:string;
    isLiked:number;

};
interface RiskData {
    No_of_Faces: number;
    No_of_Fire_Objects: number;
    No_of_Persons: number;
    No_of_Weapons: number;
    Risk_Factor: number;
  }

const handleTopicToggle = (
    data: TopicsType,
    Topics: TopicsType[],
    set_Topics: React.Dispatch<React.SetStateAction<TopicsType[]>>
) => {
    const topics_selected_img_array = [
        GN_Icon_Selected,
        Events_Icon_Selected,
        Research_Icon_Selected,
        Crime_Icon_Selected,
        Fraud_Icon_Selected,
        Politics_Icon_Selected,
        Initiatives_Icon_Selected,
        Opinions_Icon_Selected,
    ];
    const topics_notselected_img_array = [
        GN_Icon,
        Events_Icon,
        Research_Icon,
        Crime_Icon,
        Fraud_Icon,
        Politics_Icon,
        Initiatives_Icon,
        Opinions_Icon,
    ];
    set_Topics(
        Topics.map((topic) => {
            if (topic === data) {
                if (topic.filter === false) {
                    return { ...topic, img: topics_selected_img_array[topic.key - 1], filter: !topic.filter };
                } else {
                    return { ...topic, img: topics_notselected_img_array[topic.key - 1], filter: !topic.filter };
                }
            } else {
                return topic;
            }
        })
    );
};

function MyFeed() {
    const {user} = useUser();
    const navigate = useNavigate();
    const [AllPostData, set_AllPostData] = useState<AllPostDataType[]>([]);
    const [Topic, set_Topic] = useState<number>(0);
    const [Time, set_Time] = useState<number>(0);
    const [Distance, set_Distance] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [likes, setLikes] = useState<Record<number, boolean>>({}); // State for likes
    const [riskData, setRiskData] = useState<RiskData | null>(null);
    const [showRiskMeter, setShowRiskMeter] = useState(false);
    
    const storePreviousLocation = () => {
		localStorage.setItem('previousLocation', '/news');
	  };
	
	  // Navigate back to the stored location (e.g., 'My Feed') when the component mounts
	  useEffect(() => {
		const previousLocation = localStorage.getItem('previousLocation');
		if (previousLocation) {
		  localStorage.removeItem('previousLocation'); // Clear stored location
		  navigate(previousLocation); // Navigate back to 'My Feed' or stored location
		}
	  }, [navigate]);

      const navigateToPost = (postId: string) => {
        // Store the previous location before navigating to individual post
        storePreviousLocation();
        navigate(`/getpost/${postId}`);
      };
   
    // Reference to the container
    const postContainerRef = useRef<HTMLDivElement>(null);

    const formatDateToRelativeTime = (dateString:string) => {
        const date = parseISO(dateString);
        return formatDistanceToNow(date, { addSuffix: true });
      };

      const handleRiskometerClick = async (mediaFilename: string) => {
        try {
          const response = await fetch(`http://20.244.37.235:8085/analyze/${mediaFilename}`, {
            method: 'GET',
            redirect: 'follow' as RequestRedirect,
            credentials: 'include' as RequestCredentials,
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setRiskData(data);
          setShowRiskMeter(true);
        } catch (error) {
          console.error('Error fetching risk data:', error);
        }
      };
    
      const handleRiskometerClose = () => {
        setShowRiskMeter(false);
      };

    const reactPost = async (postId: number, type: string, isAnonymous: boolean) => {
        
        const requestOptions: RequestInit = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ postId, type, isAnonymous }),
          credentials: 'include',
        };
      
        try {
          const response = await fetch('https://backend.b-reporter.com/api/react', requestOptions);
          
       
          
          if (response.status !== 200 && response.status!==201) {
            // Revert the state changes if the request fails
            setLikes((prevLikes) => ({ ...prevLikes, [postId]: type === 'like' ? false : true }));
            set_AllPostData((prevPosts) =>
              prevPosts.map((post) =>
                post.id === postId
                  ? { ...post, likeCount: post.likeCount + (type === 'like' ? -1 : 1) }
                  : post
              )
            );
          }
        } catch (error) {
         
          // Revert the state changes if there's an error
          setLikes((prevLikes) => ({ ...prevLikes, [postId]: type === 'like' ? false : true }));
          set_AllPostData((prevPosts) =>
            prevPosts.map((post) =>
              post.id === postId
                ? { ...post, likeCount: post.likeCount + (type === 'like' ? -1 : 1) }
                : post
            )
          );
        }
      };
        
      const handleLikeClick = (postId: number, isAnonymous: boolean) => {
        const isLiked = likes[postId];
        const newType = isLiked ? 'dislike' : 'like';
      
        // Optimistically update the UI
        setLikes((prevLikes) => ({ ...prevLikes, [postId]: !isLiked }));
        set_AllPostData((prevPosts) =>
          prevPosts.map((post) =>
            post.id === postId
              ? { ...post, likeCount: post.likeCount + (isLiked ? -1 : 1) }
              : post
          )
        );
      
        // Perform the API request
        reactPost(postId, newType, isAnonymous);
        console.log(AllPostData);
      };
    
    const fetchPosts = async (page:number) => {
        const requestOptions: RequestInit = {
          method: 'GET',
          redirect: 'follow',
          credentials: 'include'
        };
       
        const baseUrl = 'https://backend.b-reporter.com/api/';
        const endpoint = Topic === 0 ? 'myFeed' : 'myFeedPro';
        const url = `${baseUrl}${endpoint}?limit=10&offset=${(page - 1) * 10}${Topic !== 0 ? `&topic=${Topic}` : ''}`;
    
        try {
          const response = await fetch(url, requestOptions);
          console.log("this is response from myfeed : ", response);
          const result: PostType[] = await response.json();
    
    
          const usefultemp: AllPostDataType[] = (await Promise.all(result.map(async (each) => {
            console.log("this is each from my feed: ", each);
            if (each.media[0].format.substring(0, 5) !== 'image') return null;
    
            let image = '';
            try {
              const mediaResponse = await fetch('https://backend.b-reporter.com/api/media/' + each.media[0].internalURL, requestOptions);
              const blob = await mediaResponse.blob();
              image = URL.createObjectURL(blob);
              
            } catch (error) {
              console.log('Error fetching media:', error);
              return null;
            }
    
            
            return {
              id: each.id,
              title: each.title,
              content: "",
              format: each.media[0].format.substring(0, 5),
              image_url: image,
              media_filename: each.media[0].internalURL,
              name: each.isAnonymous ? 'Anonymous' : each.user.name,
              location: `(${each.media[0].latitude}, ${each.media[0].longitude})`,
              time: `${each.createdAt}`,
              commentCount: each.commentCount,
              likeCount: each.likeCount,
              isLiked: each.isLiked,
              tags: each.tags,
              profilepicURL: each.user.profilepicURL

            } as AllPostDataType;
          }))).filter((post): post is AllPostDataType => post !== null);

           // Initialize likes state based on fetched posts
        const initialLikes = usefultemp.reduce((acc, post) => {
            acc[post.id] = post.isLiked===1? true:false;// Assuming posts are initially not liked
            return acc;
        }, {} as Record<number, boolean>);
        
    
          set_AllPostData((prevPosts) => [...prevPosts, ...usefultemp]);
          setLikes((prevLikes) => ({ ...prevLikes, ...initialLikes })); // Set the likes state
      
          // Scroll to top after loading more posts
          if (postContainerRef.current) {
            postContainerRef.current.scrollTop = 0;
        }
            } catch (error) {
            console.log('Error fetching posts:', error);
            }
        };

                
            
        useEffect(() => {
            set_AllPostData([]); // Clear existing posts
            setCurrentPage(1); // Reset current page to 1
            fetchPosts(1); // Fetch initial posts for the selected topic
        }, [Topic]);
    
        useEffect(() => {
            if (currentPage > 1) {
                fetchPosts(currentPage);
            }
        }, [currentPage]);
    
      const loadMorePosts = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    return (
        <div className='container-fluid container-myfeed'>
    <div className='row h-100'>
        <div className='col-2 col-lg-3 h-100 position-fixed left'>
            <nav className="left-navbar">
                <div>
                    <div>
                        <a>
                            <div onClick={() => { set_Topic(0); setCurrentPage(1); set_AllPostData([]) }}>
                                <i className="fa fa-light fa-house-user fa-2xl d-none d-lg-inline"></i>
                                <span className={` ${Topic == 0 ? "active" : ""}`}>General News</span>
                            </div>
                        </a>
                    </div>
                    <div>
                        <a>
                            <div onClick={() => { set_Topic(2); setCurrentPage(1); set_AllPostData([]); }}>
                                <i className="fa fa-thin fa-atom fa-2xl d-none d-lg-inline"></i>
                                <span className={` ${Topic == 2 ? "active" : ""}`}>Research</span>
                            </div>
                        </a>
                    </div>
                    <div>
                        <a>
                            <div onClick={() => { set_Topic(3); setCurrentPage(1); set_AllPostData([]); }}>
                                <i className="fa fa-thin fa-droplet fa-2xl d-none d-lg-inline"></i>
                                <span className={` ${Topic == 3 ? "active" : ""}`}>Crime</span>
                            </div>
                        </a>
                    </div>
                    <div>
                        <a>
                            <div onClick={() => { set_Topic(4); setCurrentPage(1); set_AllPostData([]); }}>
                                <i className="fa fa-thin fa-sack-dollar fa-2xl d-none d-lg-inline"></i>
                                <span className={` ${Topic == 4 ? "active" : ""}`}>Fraud</span>
                            </div>
                        </a>
                    </div>
                    <div>
                        <a>
                            <div onClick={() => { set_Topic(5); setCurrentPage(1); set_AllPostData([]); }}>
                                <i className="fa fa-thin fa-people-group fa-2xl d-none d-lg-inline"></i>
                                <span className={` ${Topic == 5 ? "active" : ""}`}>Politics</span>
                            </div>
                        </a>
                    </div>
                    <div>
                        <a>
                            <div onClick={() => { set_Topic(6); setCurrentPage(1); set_AllPostData([]); }}>
                                <i className="fa fa-light fa-person-biking fa-2xl d-none d-lg-inline"></i>
                                <span className={` ${Topic == 6 ? "active" : ""}`}>Initiatives</span>
                            </div>
                        </a>
                    </div>
                    <div>
                        <a>
                            <div onClick={() => { set_Topic(7); setCurrentPage(1); set_AllPostData([]); }}>
                                <i className="fa fa-solid fa-message fa-2xl d-none d-lg-inline"></i>
                                <span className={` ${Topic == 7 ? "active" : ""}`}>Opinions</span>
                            </div>
                        </a>
                    </div>
                </div>
            </nav>
        </div>
        <div className='col-10 col-lg-6 h-100 center'>
            <div className="content-header">
                <span>Hello {user?.name},</span>
            </div>
            {/* <div>
                <RiskoMeter  value={75}
        personCount={2}
        facesCount={2}
        fireCount={0}
        weaponsCount={1} />
            </div> */}
            <div className="posts-container" ref={postContainerRef}>
                {AllPostData.map(data => {
                    return (
                        <div className="post" key={data.id}>
                            {showRiskMeter && riskData && (
                                            <div className="riskometer-overlay">
                                            <button className="riskometer-close-button" onClick={handleRiskometerClose}>X</button>
                                            <RiskoMeter
                                                value={riskData.Risk_Factor}
                                                personCount={riskData.No_of_Persons}
                                                facesCount={riskData.No_of_Faces}
                                                fireCount={riskData.No_of_Fire_Objects}
                                                weaponsCount={riskData.No_of_Weapons}
                                            />
                                            </div>
                                        )}
                            <div className="left-column">
                                {data.profilepicURL === null || data.name === "Anonymous" ? (
                                    <img className="profile-image" src={Placeholder} alt="profile-pic" />
                                ) : (
                                    <img className="profile-image" src={`https://backend.b-reporter.com/profilepic/${data.profilepicURL}`} alt="profile-pic" />
                                )}
                            </div>
                            <div className="right-column">
                                <article className="top-row">
                                    <div className="post-header">
                                        <div className='name-time'>
                                            <strong>
                                                <span>{data.name}</span>
                                            </strong>
                                            <span className="time">{formatDateToRelativeTime(data.time)}</span>
                                        </div>
                                        <div className="details" onClick={() => handleRiskometerClick(data.media_filename)}>
                                            {/* <i className="fa fa-light fa-ellipsis fa-lg"></i> */}
                                            <i className="bi bi-speedometer2"></i>
                                        </div>
                                        
                                    </div>
                                    <p>{data.title}</p>
                                    <div className='post-img-container' style={{ position: 'relative' }} onClick={() =>  navigateToPost(data.id.toString()) }>
                                        <img src={data.image_url} alt="IIT-DELHI" className='post-image ' style={{ display: 'block', width: '100%' }} />
                                        <div className='water-mark' style={{ position: 'absolute', bottom: 0, left: 0 }}>
                                            <p style={{ margin: 0 }}>B-Reporter</p>
                                            <p style={{ margin: 0 }}>Created {formatDateToRelativeTime(data.time)}</p>
                                        </div>
                                    </div>
                                </article>
                                <div className="bottom-row">
                                    <div onClick={() =>  navigateToPost(data.id.toString()) }>
                                        <div>
                                            {/* <i className="fa fa-regular fa-comment fa-lg"></i> */}
                                            <i className="bi bi-chat-left custom-icon"></i>
                                        </div>
                                        <span>{data.commentCount}</span>
                                    </div>
                                    <div onClick={() => { handleLikeClick(data.id, data.name === "Anonymous") }}>
                                        <div>
                                            <i className={` fa fa-thumbs-up  ${likes[data.id]===true ? '' : 'fa-regular'} fa-lg`}></i>
                                        </div>
                                        <span>{data.likeCount}</span>
                                    </div>
                                    <div>
                                        <div className='btn-group dropstart'>
                                            {/* <i className="fa fa-solid fa-arrow-up-from-bracket fa-lg" data-bs-toggle="dropdown" aria-expanded="false"></i> */}
                                            <i className="bi bi-share" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                            <ul className="dropdown-menu">
                                                <li className='p-2'>
                                                    <EmailShareButton url={window.location.href} subject={data.title} body={data.content}>
                                                        <span className="share-icon">
                                                            <EmailIcon size={26} round />
                                                            &nbsp;Email
                                                        </span>
                                                    </EmailShareButton>
                                                </li>
                                                <li className='p-2'>
                                                    <FacebookShareButton url={`https://b-reporter.com/getpost/${data.id}`} quote={"See this Post on the B-Reporter"}>
                                                        <span className="share-icon">
                                                            <FacebookIcon size={26} round />
                                                            &nbsp;Facebook
                                                        </span>
                                                    </FacebookShareButton>
                                                </li>
                                                <li className='p-2'>
                                                    <LinkedinShareButton url={`https://b-reporter.com/getpost/${data.id}`} title={"See this Post on the B-Reporter"} summary={data.content} source={'https://b-reporter.com/'}>
                                                        <span className="share-icon">
                                                            <LinkedinIcon size={26} round />
                                                            &nbsp;LinkedIn
                                                        </span>
                                                    </LinkedinShareButton>
                                                </li>
                                                <li className='p-2'>
                                                    <RedditShareButton url={`https://b-reporter.com/getpost/${data.id}`} title={"See this Post on the B-Reporter"}>
                                                        <span className="share-icon">
                                                            <RedditIcon size={26} round />
                                                            &nbsp;Reddit
                                                        </span>
                                                    </RedditShareButton>
                                                </li>
                                                <li className='p-2'>
                                                    <TwitterShareButton url={`https://b-reporter.com/getpost/${data.id}`} title={"See this Post on the B-Reporter"}>
                                                        <span className="share-icon">
                                                            <TwitterIcon size={26} round />
                                                            &nbsp;Twitter
                                                        </span>
                                                    </TwitterShareButton>
                                                </li>
                                                <li className='p-2'>
                                                    <WhatsappShareButton url={`https://b-reporter.com/getpost/${data.id}`} title={"See this Post on the B-Reporter"}>
                                                        <span className="share-icon">
                                                            <WhatsappIcon size={26} round />
                                                            &nbsp;Whatsapp
                                                        </span>
                                                    </WhatsappShareButton>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='more-btn'>
                                    <span onClick={() =>  navigateToPost(data.id.toString()) }>Show More...</span>
                                    <p>{data.content}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <div className="load-more-container" onClick={loadMorePosts}>
                    <i className="fa fa-solid fa-chevron-down load-more-arrow"></i>
                </div>
            </div>
        </div>
    </div>
</div>

    );
}

export default MyFeed;


